$(document).ready(function(){
	$(window).load(function(){
		
		// Global Variables
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.site-header').outerHeight(),
		$quickLinks = $('nav.site-quicklinks').outerHeight(),
		$footerHeight = $('footer.site-footer').outerHeight();

		iOS_CaretBug();
		responsiveSlick();
		cf7formsubmit();
		tableClass();
		// stickySideBar();
		objLightcase();
		swipeCarousel();


		$(window).on('resize', function(){
			// fbTimeline();
		});
		
	});
	
//   function fbTimeline(){
// 		if($(window).width()<=480){
// 				$(".fb-page").attr('data-width', '200');
// 		}
// 		else if($(window).width()<=992){
// 			$(".fb-page").attr('data-width', '120');
// 		}
// 		else if($(window).width()<=1200){
// 			$(".fb-page").attr('data-width', '500');

// 		}
// 		else {
// 			$(".fb-page").attr('data-width', '300');
// 		}
// }
});


// IOS INPUT BUG FIX
function iOS_CaretBug() {
	var ua = navigator.userAgent,
	scrollTopPosition,
	iOS = /iPad|iPhone|iPod/.test(ua),
	iOS11 = /OS 11_0|OS 11_1|OS 11_2/.test(ua);

	// ios 11 bug caret position
 	if ( iOS && iOS11 ) {

		$(document.body).on('show.bs.modal', function(e) {
			if ( $(e.target).hasClass('modal') ) {
				// Get scroll position before moving top
				scrollTopPosition = $(document).scrollTop();

				// Add CSS to body "position: fixed"
				$("body").addClass("iosBugFixCaret");
			}
		});

     $(document.body).on('hide.bs.modal', function(e) {
			if ( $(e.target).hasClass('modal') ) {
				// Remove CSS to body "position: fixed"
				$("body").removeClass("iosBugFixCaret");

				//Go back to initial position in document
				$(document).scrollTop(scrollTopPosition);
			}
     });
   }
}

function tableClass(){
	var $tables = $(document).find('table');
	if( $tables ) {
		$tables.wrap('<div class="table-responsive"></div>');
		$tables.addClass('table');
	}
}

function smoothScrollTo(anchor) {
	var duration = 400; //time (milliseconds) it takes to reach anchor point
	var targetY = $(anchor).offset().top;
	$("html, body").animate({
		"scrollTop" : targetY
	}, duration, 'easeInOutCubic');
}

// Plugins
function objLightcase(){
	$('a[data-rel^=lightcase]').lightcase();
}

// <div class="slick-responsive" data-breakpoints="1199,4|991,2|659,2|480,1" data-thumb=".sample" data-arrows="true" data-defaultview="4">
function responsiveSlick(){
	$('.slick-responsive').each(function(){
		var breaks = $(this).data('breakpoints').split('|'),
		breakArr = [],
		arrowsval;
		$.each(breaks, function(i, items){
			breakArr.push({
				breakpoint: parseInt(items.split(',')[0]),
	      settings: {
	        slidesToShow: parseInt(items.split(',')[1])
	      }
			});
		});
		if( $(this).data('arrows') ) {
			arrowsval = true;
		} else {
			arrowsval = false;
		}
		$(this).slick({
		  dots: false,
		  infinite: true,
		  speed: 300,
		  autoplay: true,
		  arrows: arrowsval,
		  slidesToShow: $(this).data('defaultview'),
		  slidesToScroll: 1,
		  adaptiveHeight: true,
		  asNavFor: $(this).data('thumb'),
		  responsive : breakArr,
		  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
		});
	});
}

function cf7formsubmit(){
	
	$('.wpcf7-form').each(function(){
		var form = $(this);
		form.find(".wpcf7-submit, .ajax-loader").wrapAll('<div class="wcf7-wrap-btn"></div>');
		setTimeout(function(){
			var wdSubmit = form.find(".wpcf7-submit").outerWidth();
			form.find('.wcf7-wrap-btn').css({
				'width' : wdSubmit
			});
		}, 100);
	});

	$(document).on('click', '.wpcf7-submit', function(){
		var $btn = $(this);
	  var $ajaxLoader = $btn.parent('.wcf7-wrap-btn').find('.ajax-loader');
	  
	  $btn.addClass('loading');
	  $ajaxLoader.addClass('visible');

		var sitelink = $('.usd').data('usdacct');
		document.addEventListener( 'wpcf7mailsent', function( event ) {
		  location = sitelink + '/thank-you/';
		}, false );
		document.addEventListener( 'wpcf7invalid', function( event ) {
			$btn.removeClass('loading');
		 	$ajaxLoader.removeClass('visible');
		}, false );
	});
}

function carouselAnimation(){
	/* Demo Scripts for Bootstrap Carousel and Animate.css article
	* on SitePoint by Maria Antonietta Perna
	*/
  //Function to animate slider captions 
  function doAnimations( elems ) {
    //Cache the animationend event in a variable
    var animEndEv = 'webkitAnimationEnd animationend';
    
    elems.each(function () {
      var $this = $(this),
        $animationType = $this.data('animation');
      $this.addClass($animationType).one(animEndEv, function () {
        $this.removeClass($animationType);
      });
    });
  }
  
  //Variables on page load 
  var $myCarousel = $('.carousel'),
    $firstAnimatingElems = $myCarousel.find('.item:first').find("[data-animation ^= 'animated']");
      
  //Initialize carousel 
  $myCarousel.carousel();
  
  //Animate captions in first slide on page load 
  doAnimations($firstAnimatingElems);
  
  //Pause carousel  
  $myCarousel.carousel('pause');
  
  
  //Other slides to be animated on carousel slide event 
  $myCarousel.on('slide.bs.carousel', function (e) {
    var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
    doAnimations($animatingElems);
  });

  $('.carousel').carousel();
}



/* MODERNIZR LAYOUT - This serves as the the media query inside the Javascript */
function screenBpoints(){
	if( Modernizr.mq('(min-width: 1200px)') ) {
	}
	else if( Modernizr.mq('(min-width: 992px)') && Modernizr.mq('(max-width: 1199px)') ) {
	}
	else if( Modernizr.mq('(max-width: 991px)') && Modernizr.mq('(min-width: 768px)')){
	}
	else{
	}
}

function stickySideBar(){
	var $navHeight = $('header.site-header').outerHeight();
	if( Modernizr.mq('(min-width: 768px)') ) {
		$('.sticky-bar').each(function(){
			$(this).stickySidebar({
				topSpacing: $navHeight + 30
			});
		});
	}
}
/* BOOTSTRAP CAROUSEL - Enabling the issue on swiping */
function swipeCarousel() {
	$(".carousel").each(function(){
		var $carousel = $(this);
	  $carousel.carousel({
	    interval: false,
	    pause: true
	  });
	  if( $(this).attr('data-touch') ) {
		  $carousel.find( '.carousel-inner' ).swipe( {
		    swipeLeft: function ( event, direction, distance, duration, fingerCount ) {
		      this.parent( ).carousel( 'next' );
		    },
		    swipeRight: function ( ) {
		      this.parent( ).carousel( 'prev' );
		    },
		    threshold: 0,
		    tap: function(event, target) {
		      window.location = $(this).find('.carousel-item.active a').attr('href');
		    },

		    excludedElements:"label, button, input, select, textarea, .noSwipe"
		  } );
		   $carousel.find('.carousel-inner').on('dragstart', 'a', function () {
		    return false;
		  }); 
	  }
	});
}