$(document).ready(function(){
	$(window).load(function(){

		show_more_blog()
		show_more_post()
		careerPosition()
		// Global Variables
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.site-header').outerHeight(),
		$quickLinks = $('nav.site-quicklinks').outerHeight(),
		$footerHeight = $('footer.site-footer').outerHeight();

		$('.menu-container .menu-contain').css({
			'padding-bottom' : $quickLinks,
			'padding-top' : $navHeight
		});

		function careerPosition() {
			var positionTitle = $('#career-position').data('title');
			//console.log(positionTitle);
			$('#input_2_3').val(positionTitle);
		}

		$(document).on('click', '.mobile-menu.notAnimated', function(){
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$(this).removeClass('notAnimated').addClass('animated');
			$('.menu-container').addClass('show');
			setTimeout(function(){
				$('.menu-container').addClass('animate');
			}, 500);
		});

		$(document).on('click', '.mobile-menu.animated', function(){
			$(this).removeClass('animated').addClass('notAnimated');

			$('.menu-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.menu-container').removeClass('show');
			}, 500);

			setTimeout(function(){
				$('.menu-container .menu-contain nav ul li .sub-menu').removeClass('show');
			}, 700);

		});

		$(document).on('click', '.submenu-button', function(){
			$(this).parent('li').find(' > .sub-menu').addClass('show');
		});
		$(document).on('click', '.submenu-close-button', function(){
			$(this).parent('li').parent('ul').removeClass('show');
		});

		$(document).on('click', 'a[href^="mailto:"].main', function(e){
			e.preventDefault();
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$('.mailto-container').addClass('show');
			setTimeout(function(){
				$('.mailto-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.mailto-close-button', function(){
			$('.mailto-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.mailto-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '.search-btn', function(){
			$('body, html').css({
				'overflow' : 'hidden'
			});
			$('.search-form-template').addClass('show');
			setTimeout(function(){
				$('.search-form-template').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.search-close-button', function(){
			$('body, html').removeAttr('style');
			$('.search-form-template').removeClass('show');
			setTimeout(function(){
				$('.search-form-template').removeClass('animate');
			}, 300);
		});

		

		$(document).on('click', '.popup-close', function(){
			$('body, html').removeAttr('style');
			$('header.site-header.push-top').removeAttr('style');
			$('.popup-modal-container').removeClass('show');
			$('html').removeClass('popupshow');
			setTimeout(function(){
				$('.popup-modal-container').removeClass('animate');
			}, 300);
		});


		$(document).on('click', '.apply-btn', function(e){
			var position = $(this).data('position');
			e.preventDefault();
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$('.career-container .yourPosition input').val(position);
			$('.career-container').addClass('show');
			setTimeout(function(){
				$('.career-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.career-close-button', function(){
			$('.career-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.career-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '#resumeBtn', function(){
			var input = $('.resume-container .resume input');
			input.click();
		});

		$('input#input_2_1').change(function(e){
			var FileName = e.target.files[0];
			var inputWidth = $('input#input_2_1').outerWidth();
			$('.filename').remove();
			$('input#input_2_1').before("<p class='filename'>"+ FileName.name +"</p>");
			//console.log(inputWidth);
			$('.filename').css('max-width', inputWidth+'px');
		});

		$(document).on('click', '.expand-term', function(e){
			e.preventDefault();
			$(this).toggleClass('fa-minus').toggleClass('fa-plus');
			$(this).parent('a').parent('.parent-term').find('.child-term').first().toggleClass('active');
		});

		$(document).on('click', '.expand-selection-term', function(e){
			$('.archive-sidebar .term-container').toggleClass('active');
		});

		$(document).on('click', '.parent-backdrop .backdrop', function(){
			$('body, html').removeAttr('style');
			$(this).parent('.parent-backdrop').removeClass('show');
			setTimeout(function(){
				$(this).parent('.parent-backdrop').removeClass('animate');
			}, 300);
		});
		

		(function () {
			var indexOf = [].indexOf;
		
			if (indexOf.call(window, 'ontouchstart') >= 0 === false) {
				$("body").mousemove(function (e) {
					var moveX, moveY;
					moveX = e.pageX * -1 / 25 + 'px';
					moveY = e.pageY * -1 / 25 + 'px';
					var moveVertical = $('.move-vertical').css('background-position', 'center calc(50% + ' + moveY + ')');
					var moveVerticalRight = $('.move-vertical-right').css('background-position', 'right calc(70% + ' + moveY + ')');
					var moveHorizontal = $('.move-horizontal').css('background-position', 'calc(50% + ' + moveX + ') center');
					var moveAll = $('.move-all').css('background-position', 'calc(50% + ' + moveX + ') calc(50% + ' + moveY + ')');

					return moveHorizontal, moveVertical, moveVerticalRight, moveAll;
				});
			}
		
		}).call(this);

		// $(".homepage-slider").slick({
		// 	dots: true,
		// 	infinite: true,
		// 	autoplay: true,
		// 	speed: 300,
		// 	arrows: true,
		// 	slidesToShow: 1,
		// 	slidesToScroll: 1,
		// 	adaptiveHeight: false,
		// 	fade: true,
		// 	prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		// 	nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
		// });
		
	$(document).on('click', '.ul.slick-dots li a', function (e) {
		e.preventDefault();
	});
		
		$(".awardsSlider").slick({
		  dots: false,
		  infinite: true,
		  speed: 300,
		  autoplay: true,
		  arrows: true,
		  slidesToShow: 1,
		  slidesToScroll: 1,
		  adaptiveHeight: false,
		  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
		});

		$('.product-image').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			fade: true,
			infinite: false,
			draggable: false,
			asNavFor: '.product-gallery'
		});
		$('.product-gallery').slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			asNavFor: '.product-image',
			vertical: true,
			infinite: false,
			// centerMode: true,
			focusOnSelect: true,
			arrows: false
		});
		$('.slider-products').slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			arrows: true,
			// fade: true, not working when slides are more than 1
			infinite: true,
		  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
		});

		$('.category-slider').slick({
			centerMode: true,
			centerPadding: '30%',
			slidesToShow: 1,
			infinite: true,
		  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
			responsive: [
				{
					breakpoint: 1600,
					settings: {
						centerMode: true,
						centerPadding: '26.5%',
						slidesToShow: 1
					}
				},
				{
					breakpoint: 480,
					settings: {
						centerMode: true,
						centerPadding: '10%',
						slidesToShow: 1
					}
				}
			]
		});

		
//News-ajax-Loadmore
function show_more_blog() {
	var $page = 2;
	$(document).on('click', '.show-more-blog', function (e) {
		e.preventDefault();
		$.ajax({
			url: $('.ajaxlocation').data('ajaxlocation'),
			dataType: 'json',
			type: 'POST',
			data: {
				action: 'show_more_blog',
				page: $page
			},
			beforeSend: function beforeSend() {
				$('.show-more-blog').html('Loading...');
			},
			success: function success(response) {
				$('.show-more-blog').html('Load more');
				$('#news-lists').append(response.content);
				var $maxPaged = parseInt(response.max);
				if ($maxPaged === $page) {
					$('.show-more-blog').hide();
					$('.show-less-blog').css('display','inline-block');
				} else {
					$page++;
				}
			}
		});
	});
	$(document).on('click', '.show-less-blog', function (e) {
		e.preventDefault();
    $("#news-section").load(window.location.href + " #news-section");
	});
}
//Campaign-ajax-Loadmore
function show_more_post() {
	var $page = 2;
	$(document).on('click', '.show-more-post', function (e) {
		e.preventDefault();
		$.ajax({
			url: $('.ajaxlocation').data('ajaxlocation'),
			dataType: 'json',
			type: 'POST',
			data: {
				action: 'show_more_post',
				page: $page
			},
			beforeSend: function beforeSend() {
				$('.show-more-post').html('Loading...');
			},
			success: function success(response) {
				$('.show-more-post').html('Load more');
				$('#campaign-section').append(response.content);
				var $maxPaged = parseInt(response.max);
				if ($maxPaged === $page) {
					$('.show-more-post').hide();
					$('.show-less-post').css('display','inline-block');
				} else {
					$page++;
				}
			}
		});
	});
	$(document).on('click', '.show-less-post', function (e) {
		e.preventDefault();
    // $("#news-section").load(window.location.href + " #news-section");
	});
}

	});
});
